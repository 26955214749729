import React from "react"
import { PageProps } from "gatsby"

import { Layout } from "../components"
import { ScenarioDisplay } from "../features/Scenario/components"

const ScenarioPage = ({ location }: PageProps): JSX.Element => {
  //TODO - use a path matching library to extract this - or a router
  const idMatch = location.pathname.match(/\/scenario\/(.*)\/?/)
  if (idMatch) {
    return (
      <Layout>
        <ScenarioDisplay scenarioId={idMatch[1].replace("/", "")} />
      </Layout>
    )
  } else {
    return <div>broken</div>
  }
}

export default ScenarioPage
